<template>
    <div>
      <Header />
      <div class="container mx-auto my-10">
        <breadcrumb></breadcrumb>
        <div class="flex flex-col md:flex-row gap-4 p-4 ml-2">
            <div class="border border-grey-2 rounded-xl w-full md:w-1/3 px-16 py-4 font-bold">
                <div class="mb-4">Waiver</div>
                <div class="flex flex-col gap-4">
                    <div class="cursor-pointer font-bold hover:bg-primary hover:text-white py-2 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-center" :class="{'bg-primary text-white' : (selectedWaiver.nowaiver == waiver.nowaiver || waiver.sudah != '0'), 'bg-white text-primary' : (selectedWaiver.nowaiver != waiver.nowaiver && waiver.sudah == '0')}" v-for="(waiver, idxwaiver) in listWaiver" :key="idxwaiver" @click="checkWaiver(waiver)">
                        <div class="grid grid-cols-1 content-center text-center">
                            Waiver Unit {{waiver.nowaiver}}
                        </div>
                        <div class="grid grid-cols-1 content-center" v-if="waiver.sudah != '0'">
                            <font-awesome-icon icon="fa-solid fa-circle-check" class="text-yellow"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="border border-grey-2 rounded-xl w-full px-8 py-4 font-bold">
                <div class="flex flex-row justify-between mb-4">
                    <div class="">Registration Form Waiver Unit {{selectedWaiver.nowaiver}}</div>
                    <div class="">
                        <button @click="showDropdownLang = !showDropdownLang" id="dropdownDefault" data-dropdown-toggle="dropdown" class="text-gray-700 bg-gray-100 border border-gray-400 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center" type="button">{{lang}}<svg class="ml-2 w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg></button>
                        <!-- Dropdown menu -->
                        <div id="dropdown" class="absolute z-10 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700" :class="{'hidden': !showDropdownLang}">
                            <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                            <li>
                                <a href="#" @click="selectLang('EN')" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">EN</a>
                            </li>
                            <li>
                                <a href="#" @click="selectLang('ID')" class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">ID</a>
                            </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ValidationObserver v-slot="{ invalid }">
                <div class="flex flex-col gap-2">
                    <div class="flex gap-8 font-bold text-xl">
                        <span>
                            {{dataLang.rider}}
                        </span>
                        <div class="flex text-base gap-2 items-center" v-if="selectedWaiver.sudah == '0'">
                            <div class="rounded-md border-2 border-primary grid items-center h-6 w-6 justify-items-center text-xl text-primary cursor-pointer" @click="handleUseMyData">
                                <font-awesome-icon icon="fa-solid fa-square-check" v-if="form.useMyData"/>
                            </div>
                            <span>{{dataLang.use_my_data}}</span>
                        </div>
                    </div>
                    <div class="flex flex-col md:grid md:grid-cols-2 gap-8 font-bold w-full pt-4">
                        <div class="flex flex-col gap-4">
                            <div class="w-full">
                                <label for="rider_name" class="block mb-2 text-base font-bold">{{dataLang.name}}</label>
                                <ValidationProvider class="block mt-3" name="name" :rules="{ required: true }">
                                    <input type="text" id="rider_name" v-model="form.waiverRiderForm.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.rider_name" :disabled="selectedWaiver.sudah != '0'">
                                </ValidationProvider>
                            </div>
                            <div class="w-full">
                                <label for="rider_phone" class="block mb-2 text-base font-bold">{{dataLang.phone_number}}</label>
                                <ValidationProvider class="block mt-3" name="phone" :rules="{ required: true, regex: /^[0-9]+$/, min:5, max: 20 }">
                                    <input type="text" id="rider_phone" v-model="form.waiverRiderForm.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="0812xxxxxxx" :disabled="selectedWaiver.sudah != '0'">
                                </ValidationProvider>
                            </div>
                            <!-- <div class="w-full">
                                <label for="rider_email" class="block mb-2 text-base font-bold">{{dataLang.email}}</label>
                                <ValidationProvider class="block mt-3" name="email" :rules="{ required: true }">
                                    <input type="email" id="rider_email" v-model="form.waiverRiderForm.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="user@tripwe.com" :disabled="selectedWaiver.sudah != '0'">
                                </ValidationProvider>
                            </div> -->
                            <div class="w-full">
                                <label for="rider_instagram" class="block mb-2 text-base font-bold">{{dataLang.instagram}}</label>
                                <ValidationProvider class="block mt-3" name="instagram" :rules="{ required: true }">
                                    <input type="text" id="rider_instagram" v-model="form.waiverRiderForm.instagram" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="Instagram & Tiktok" :disabled="selectedWaiver.sudah != '0'">
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col gap-4">
                            <div class="w-full">
                                <label for="rider_id_card_no" class="block mb-2 text-base font-bold">{{dataLang.id_card_passport}}</label>
                                <ValidationProvider class="block mt-3" name="id_card_no" :rules="{ required: false, regex: /^[0-9]+$/ }">
                                    <input type="text" id="rider_id_card_no" v-model="form.waiverRiderForm.id_card_no" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="3372***********" :disabled="selectedWaiver.sudah != '0'">
                                </ValidationProvider>
                            </div>
                            <div class="flex flex-row gap-8">
                                <div class="w-full">
                                    <label for="rider_birth_place" class="block mb-2 text-base font-bold">{{dataLang.birth_place}}</label>
                                    <ValidationProvider class="block mt-3" name="birth_place" :rules="{ required: true }">
                                        <input type="text" id="rider_birth_place" v-model="form.waiverRiderForm.birth_place" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.city" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                                <div class="w-full">
                                    <label for="rider_dob" class="block mb-2 text-base font-bold">{{dataLang.date_of_birth}}</label>
                                    <ValidationProvider class="block mt-3" name="dob" :rules="{ required: true }">
                                        <v-date-picker v-model="form.waiverRiderForm.dob" ref="riderDobRef" :max-date="maxDateRider" :masks="{ L: 'DD/MM/YYYY' }" @dayclick="onDayClick">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <div class="mt-1 relative rounded-md w-4/5">
                                                <input type="text" id="rider_dob" :value="inputValue" v-on="inputEvents" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="dd/mm/yy" :disabled="selectedWaiver.sudah != '0'">
                                            </div>
                                        </template>
                                        </v-date-picker>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="flex flex-row gap-8">
                                <div class="w-full">
                                    <label for="rider_emergency_contact_name" class="block mb-2 text-base font-bold">{{dataLang.emergency_contact_name}}</label>
                                    <ValidationProvider class="block mt-3" name="emergency_contact_name" :rules="{ required: true }">
                                        <input type="text" id="rider_emergency_contact_name" v-model="form.waiverRiderForm.emergency_contact_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.emergency_contact_name" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                                <div class="w-full">
                                    <label for="rider_emergency_contact" class="block mb-2 text-base font-bold">{{dataLang.emergency_contact}}</label>
                                    <ValidationProvider class="block mt-3" name="emergency_contact" :rules="{ required: true, regex: /^[0-9]+$/, min:5, max: 20 }">
                                        <input type="text" id="rider_emergency_contact" v-model="form.waiverRiderForm.emergency_contact" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="0812xxxxxxxxx" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col md:grid md:grid-cols-2 gap-8 font-bold w-full py-4">
                        <div class="w-full mt-3">
                            <div class="flex text-base gap-2 items-center" v-if="selectedWaiver.sudah == '0'">
                                <div class="rounded-md border-2 border-primary grid items-center h-6 w-6 justify-items-center text-xl text-primary cursor-pointer" @click="form.withPassenger = !form.withPassenger">
                                    <font-awesome-icon icon="fa-solid fa-square-check" v-if="form.withPassenger"/>
                                </div>
                                <span>{{dataLang.with_passenger}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="form.withPassenger">
                        <div class="flex flex-col md:grid md:grid-cols-2 gap-8 font-bold w-full">
                            <div class="flex flex-col gap-4">
                                <div class="w-full">
                                    <label for="name" class="block mb-2 text-base font-bold">{{dataLang.name}}</label>
                                    <ValidationProvider class="block mt-3" name="pname" :rules="{ required: form.withPassenger }">
                                        <input type="text" id="name" v-model="form.waiverPassengerForm.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.passenger_name" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                                <div class="w-full">
                                    <label for="phone" class="block mb-2 text-base font-bold">{{dataLang.phone_number}}</label>
                                    <ValidationProvider class="block mt-3" name="p_phone" :rules="{ required: form.withPassenger, regex: /^[0-9]+$/, min:5, max: 20 }">
                                        <input type="text" id="phone" v-model="form.waiverPassengerForm.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="0812xxxxxxx" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                                <!-- <div class="w-full">
                                    <label for="email" class="block mb-2 text-base font-bold">{{dataLang.email}}</label>
                                    <ValidationProvider class="block mt-3" name="p_email" :rules="{ required: form.withPassenger }">
                                        <input type="email" id="email" v-model="form.waiverPassengerForm.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="user@tripwe.com" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div> -->
                                <div class="w-full">
                                    <label for="p_instagram" class="block mb-2 text-base font-bold">{{dataLang.instagram}}</label>
                                    <ValidationProvider class="block mt-3" name="p_instagram" :rules="{ required: form.withPassenger }">
                                        <input type="text" id="p_instagram" v-model="form.waiverPassengerForm.instagram" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="Instagram & Tiktok" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="flex flex-col gap-4">
                                <div class="w-full">
                                    <label for="id_card_no" class="block mb-2 text-base font-bold">{{dataLang.id_card_passport}}</label>
                                    <ValidationProvider class="block mt-3" name="p_id_card_no" :rules="{ required: false, regex: /^[0-9]+$/ }">
                                        <input type="text" id="id_card_no" v-model="form.waiverPassengerForm.id_card_no" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="3372***********" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                                <div class="flex flex-row gap-8">
                                    <div class="w-full">
                                        <label for="birth_place" class="block mb-2 text-base font-bold">{{dataLang.birth_place}}</label>
                                        <ValidationProvider class="block mt-3" name="p_birth_place" :rules="{ required: form.withPassenger}">
                                            <input type="text" id="birth_place" v-model="form.waiverPassengerForm.birth_place" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.city" :disabled="selectedWaiver.sudah != '0'">
                                        </ValidationProvider>
                                    </div>
                                    <div class="w-full">
                                        <label for="date_of_birth" class="block mb-2 text-base font-bold">{{dataLang.date_of_birth}}</label>
                                        <ValidationProvider class="block mt-3" name="p_dob" :rules="{ required: form.withPassenger }">
                                            <v-date-picker v-model="form.waiverPassengerForm.dob" ref="passengerDobRef" :max-date="maxDatePassenger" :masks="{ L: 'DD/MM/YYYY' }">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <div class="mt-1 relative rounded-md w-4/5">
                                                    <input type="text" id="date_of_birth" :value="inputValue" v-on="inputEvents" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="dd/mm/yy" :disabled="selectedWaiver.sudah != '0'">
                                                </div>
                                            </template>
                                            </v-date-picker>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="flex flex-row gap-8">
                                    <div class="w-full">
                                        <label for="emergency_contact_name" class="block mb-2 text-base font-bold">{{dataLang.emergency_contact_name}}</label>
                                        <ValidationProvider class="block mt-3" name="p_emergency_contact_name" :rules="{ required: form.withPassenger}">
                                            <input type="text" id="emergency_contact_name" v-model="form.waiverPassengerForm.emergency_contact_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.emergency_contact_name" :disabled="selectedWaiver.sudah != '0'">
                                        </ValidationProvider>
                                    </div>
                                    <div class="w-full">
                                        <label for="emergency_contact" class="block mb-2 text-base font-bold">{{dataLang.emergency_contact}}</label>
                                        <ValidationProvider class="block mt-3" name="p_emergency_contact" :rules="{ required: form.withPassenger}">
                                            <input type="text" id="emergency_contact" v-model="form.waiverPassengerForm.emergency_contact" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="0812xxxxxxxxx" :disabled="selectedWaiver.sudah != '0'">
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full font-light text-sm">
                            <p>{{dataLang.left_blank}}</p>
                        </div>
                    </div>
                    <div v-if="form.needRepresentative">
                        <div class="flex flex-col md:grid md:grid-cols-2 gap-8 font-bold w-full py-4">
                            <div class="w-full mt-3">
                                <span>{{dataLang.representative}}</span>
                            </div>
                        </div>
                        <div class="flex flex-col md:grid md:grid-cols-2 gap-8 font-bold w-full mb-4">
                            <div class="flex flex-col gap-4">
                                <div class="w-full">
                                    <label for="name" class="block mb-2 text-base font-bold">{{dataLang.name}}</label>
                                    <ValidationProvider class="block mt-3" name="pname" :rules="{ required: form.needRepresentative }">
                                        <input type="text" id="name" v-model="form.waiverRepresentativeForm.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" :placeholder="dataLang.representative_name" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                                <div class="w-full">
                                    <label for="phone" class="block mb-2 text-base font-bold">{{dataLang.phone_number}}</label>
                                    <ValidationProvider class="block mt-3" name="p_phone" :rules="{ required: form.needRepresentative, regex: /^[0-9]+$/, min:5, max: 20 }">
                                        <input type="text" id="phone" v-model="form.waiverRepresentativeForm.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="0812xxxxxxx" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="flex flex-col gap-4">
                                <div class="w-full">
                                    <label for="id_card_no" class="block mb-2 text-base font-bold">{{dataLang.id_card_passport}}</label>
                                    <ValidationProvider class="block mt-3" name="p_id_card_no" :rules="{ required: false, regex: /^[0-9]+$/ }">
                                        <input type="text" id="id_card_no" v-model="form.waiverRepresentativeForm.id_card_no" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="3372***********" :disabled="selectedWaiver.sudah != '0'">
                                    </ValidationProvider>
                                </div>
                                <div class="flex flex-col gap-4">
                                    <div class="w-full">
                                        <label for="date_of_birth" class="block mb-2 text-base font-bold">{{dataLang.date_of_birth}}</label>
                                        <ValidationProvider class="block mt-3" name="p_dob" :rules="{ required: form.needRepresentative }">
                                            <v-date-picker v-model="form.waiverRepresentativeForm.dob" ref="passengerDobRef" :masks="{ L: 'DD/MM/YYYY' }">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <div class="mt-1 relative rounded-md">
                                                    <input type="text" id="date_of_birth" :value="inputValue" v-on="inputEvents" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 disabled:cursor-not-allowed" placeholder="dd/mm/yy" :disabled="selectedWaiver.sudah != '0'">
                                                </div>
                                            </template>
                                            </v-date-picker>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col font-bold w-full py-4">
                        <div class="flex flex-row justify-between text-primary cursor-pointer" @click="tab.rule = !tab.rule"><span>{{dataLang.rules_order_label}}</span> <font-awesome-icon icon="fa-solid fa-caret-up" v-if="tab.rule"/><font-awesome-icon icon="fa-solid fa-caret-down" v-else/></div>
                        <div class="py-4 px-6" v-if="tab.rule">
                            <ol class="list-decimal">
                                <li v-for="(rule, idxrule) in filterRules" :key="idxrule" :value="rule.rule_no" :class="{'no-list-style': rule.rule_no == ''}">
                                    <p class="text-base">
                                        {{rule.rule_text}}
                                        <ol class="list-disc pl-6">
                                            <li v-for="(child, idxchild) in rule.childrens" :key="idxchild">
                                                <p class="text-base">
                                                    {{child.rule_text}}
                                                </p>
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="flex flex-col font-bold w-full py-4">
                        <div class="flex flex-row justify-between text-primary cursor-pointer" @click="tab.disclaimer = !tab.disclaimer"><span>{{dataLang.disclaimer}}</span> <font-awesome-icon icon="fa-solid fa-caret-up" v-if="tab.disclaimer"/><font-awesome-icon icon="fa-solid fa-caret-down" v-else/></div>
                        <div class="py-4 px-6" v-if="tab.disclaimer">
                            <ol class="list-disc">
                                <li v-for="(rule, idxrule) in filterDisclaimer" :key="idxrule">
                                    <p class="text-base">
                                        {{rule.rule_text}}
                                        <ol class="list-disc pl-6">
                                            <li v-for="(child, idxchild) in rule.childrens" :key="idxchild">
                                                <p class="text-base">
                                                    {{child.rule_text}}
                                                </p>
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                            </ol>
                            <div v-for="(rule, idxrule) in dataRules.penutup" :key="idxrule">
                              <p class="text-base py-4" v-html="nl2br(rule.rule_text)"></p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col font-bold w-full py-4">
                        <div class="flex flex-row justify-between text-primary cursor-pointer" @click="tab.declare = !tab.declare"><span>{{dataLang.i_declare}}</span> <font-awesome-icon icon="fa-solid fa-caret-up" v-if="tab.declare"/><font-awesome-icon icon="fa-solid fa-caret-down" v-else/></div>
                        <div class="py-4 px-6" v-if="tab.declare">
                            <ol class="list-decimal py-4">
                                <li v-for="(rule, idxrule) in filterStatement" :key="idxrule">
                                    <p class="text-base">
                                        {{rule.rule_text}}
                                        <ol class="list-disc pl-6">
                                            <li v-for="(child, idxchild) in rule.childrens" :key="idxchild">
                                                <p class="text-base">
                                                    {{child.rule_text}}
                                                </p>
                                            </li>
                                        </ol>
                                    </p>
                                </li>
                            </ol>
                            <div v-for="(rule, idxrule) in dataRules.statement_penutup">
                                <p class="text-base py-4" v-html="nl2br(rule.rule_text)"></p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="flex flex-col gap-4 pt-4" v-if="selectedWaiver.sudah == '0'">
                        <div class="w-full">
                            <div class="flex flex-row text-sm gap-2 items-center">
                                <div class="rounded-md border-2 border-primary grid items-center h-6 w-6 justify-items-center text-xl text-primary cursor-pointer" @click="form.iHaveRead = !form.iHaveRead">
                                    <font-awesome-icon icon="fa-solid fa-square-check" v-if="form.iHaveRead"/>
                                </div>
                                <span class="flex-1">{{dataLang.i_have_read}}</span>
                            </div>
                        </div>
                        <div class="w-full">
                            <div class="flex flex-row text-sm gap-2 items-center">
                                <div class="rounded-md border-2 border-primary grid items-center h-6 w-6 justify-items-center text-xl text-primary cursor-pointer" @click="form.iAgree = !form.iAgree">
                                    <font-awesome-icon icon="fa-solid fa-square-check" v-if="form.iAgree"/>
                                </div>
                                <span class="flex-1">{{dataLang.i_agree_statement}}</span>
                            </div>
                        </div>
                    </div> -->
                    <div class="grid grid-cols-2" v-if="isJetski">
                        <div class="flex flex-col gap-4 pt-4">
                            <!-- <span class="text-center">&nbsp;</span>
                            <span class="text-center">{{ dataRules.signature[0].rule_text }}</span>
                            <div class="w-full grid justify-items-center" v-if="!isUpdate">
                                <VueSignaturePad width="400px" height="250px" ref="signaturePad" class="rounded-xl border-2 border-grey-400" />
                                <button class="button-danger text-lg mt-5 px-10 mx-auto" type="button" @click="$refs.signaturePad.clearSignature()">Clear</button>
                            </div> -->
                        </div>
                        <div class="flex flex-col gap-4 pt-4" v-if="selectedWaiver.sudah == '0'">
                            <span class="text-center">{{ dataRules.kota }}, {{ $moment().format("DD MMMM YYYY") }}</span>
                            <span class="text-center">{{ filterSignatureUser.rule_text }}</span>
                            <div class="w-full grid justify-items-center">
                                <VueSignaturePad width="400px" height="250px" ref="signaturePad2" class="rounded-xl border-2 border-grey-400" />
                                <button class="button-danger text-lg mt-5 px-10 mx-auto" type="button" @click="$refs.signaturePad2.clearSignature()">Clear</button>
                            </div>
                        </div>
                        <div class="flex flex-col gap-4 pt-4" v-else>
                            <span class="text-center">{{ dataRules.kota }}, {{ $moment(form.created_at).format("DD MMMM YYYY") }}</span>
                            <span class="text-center">{{ filterSignatureUser.rule_text }}</span>
                            <div class="w-full grid justify-items-center">
                                <img :src="form.waiverRiderForm.signature_driver" alt="Signature">
                            </div>
                        </div>
                    </div>
                    <button v-if="selectedWaiver.sudah == '0'" :disabled="invalid" class="button-submit text-lg px-10 mx-auto" type="button" @click="send">{{dataLang.send}}</button>
                </div>
                </ValidationObserver>
            </div>
        </div>
      </div>
      <Footer />
    </div>
</template>

<script>
  import Breadcrumb from '@/components/layout/Breadcrumb.vue';
  import Header from '@/components/Header';
  import Footer from '@/components/Footer';
  import langID from '@/locale/id.json';
  import langEN from '@/locale/en.json';

  export default {
    name: 'Waiver',
    components: {
      Header,
      Footer,
      Breadcrumb,
    },
    data: () => ({
      listWaiver: [],
      selectedWaiver: {},
      showDropdownLang: false,
      lang: "EN",
      tab: {
        rule: true,
        disclaimer: false,
        declare: false,
      },
      form:{
        useMyData: false,
        withPassenger: false,
        needRepresentative: false,
        iHaveRead: false,
        iAgree: false,
        waiverRiderForm: {
          name: "",
          phone: "",
          email: "",
          id_card_no: "",
          birth_place: "",
          dob: "",
          emergency_contact: "",
          emergency_contact_name: "",
          instagram: "",
          signature_driver: "",
          signature_toko: "",
        },
        waiverPassengerForm: {
          name: "",
          phone: "",
          email: "",
          id_card_no: "",
          birth_place: "",
          dob: "",
          emergency_contact: "",
          emergency_contact_name: "",
          instagram: "",
        },
        waiverRepresentativeForm: {
          name: "",
          phone: "",
          id_card_no: "",
          dob: "",
        },
      },
      formDefault:{
        useMyData: false,
        withPassenger: false,
        iHaveRead: false,
        iAgree: false,
        waiverRiderForm: {
          name: "",
          phone: "",
          email: "",
          id_card_no: "",
          birth_place: "",
          dob: "",
          emergency_contact: "",
          emergency_contact_name: "",
          instagram: "",
          signature_driver: "",
          signature_toko: "",
        },
        waiverPassengerForm: {
          name: "",
          phone: "",
          email: "",
          id_card_no: "",
          birth_place: "",
          dob: "",
          emergency_contact: "",
          emergency_contact_name: "",
          instagram: "",
        },
        waiverRepresentativeForm: {
          name: "",
          phone: "",
          id_card_no: "",
          dob: "",
        },
      },
      dataWaiver: {},
      dataTrx: {},
      dataRules: {},
    }),
    async created () {
        this.$store.commit('setBreadcrumbItems', [
            { text: 'Home', routeName: 'Home' },
            { text: 'Transaction', routeName: 'Invoice', params: {
                slug: 'process',
                trip: 'safari',
              } },
            { text: 'Waiver', routeName: 'Waiver' },
        ]);
        this.dataTrx = this.$store.state.waiverPackage;
        this.loadListWaiver();
        this.loadWaiverRule();
    },
    computed: {
        dataLang(){
            if(this.lang == "EN"){
                return langEN;
            }else{
                return langID;
            }
        },
        filterRules(){
            return this.dataRules.peraturan;
        },
        filterDisclaimer(){
            return this.dataRules.perhatian;
        },
        filterStatement(){
            return this.dataRules.statement;
        },
        filterSignatureUser(){
            if(this.dataRules?.signature == null){
                return {};
            }
            return this.dataRules?.signature[1] ?? {};
        },
        isJetski(){
            if(this.$store.state.waiverPackage.tipe != "OFFROAD" && this.$store.state.waiverPackage.tipe != "ONROAD"){
                return true;
            }
            return false;
        },
        checkValid(){
            var valid = true;
            if(!this.form.iHaveRead){
                valid = false;
            }
            if(!this.form.iAgree){
                valid = false;
            }
            if(this.form.waiverRiderForm.phone == this.form.waiverRiderForm.emergency_contact){
                valid = false;
            }
            if(this.form.withPassenger && this.form.waiverPassengerForm.phone == this.form.waiverPassengerForm.emergency_contact){
                valid = false;
            }
            return valid;
        },
        maxDateRider(){
            return this.$moment().subtract(14, 'years').format('YYYY-MM-DD');
        },
        maxDatePassenger(){
            return this.$moment().subtract(12, 'years').format('YYYY-MM-DD');
        },
    },
    methods: {
        nl2br (str, is_xhtml) {
            if (typeof str === 'undefined' || str === null) {
                return '';
            }
            var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
        },
        selectLang(lang){
            this.lang = lang;
            this.showDropdownLang = false;
            this.loadWaiverRule();
        },
        onDayClick(){
          if(this.$moment().diff(this.form.waiverRiderForm.dob, 'years') >= 14 && this.$moment().diff(this.form.waiverRiderForm.dob, 'years') < 17){
            this.form.needRepresentative = true
          }else{
            this.form.needRepresentative = false
          }
        },
        validate(){
            // if(!this.form.iHaveRead){
            //     this.$toasted.global.error("Please check all data");
            //     return false;
            // }
            // if(!this.form.iAgree){
            //     this.$toasted.global.error("Please check all data");
            //     return false;
            // }
            if(this.form.waiverRiderForm.phone == this.form.waiverRiderForm.emergency_contact){
                this.$toasted.global.error("Rider's emergency phone numbers cannot be the same as the rider's phone number");
                return false;
            }
            if(this.form.withPassenger && this.form.waiverPassengerForm.phone == this.form.waiverPassengerForm.emergency_contact){
                this.$toasted.global.error("Passenger's emergency phone numbers cannot be the same as the passenger's phone number");
                return false;
            }
            return true;
        },
        handleUseMyData(){
            this.form.useMyData = !this.form.useMyData;
            if(this.form.useMyData){
                this.form.waiverRiderForm.name = this.$store.state.user.nama;
                this.form.waiverRiderForm.phone = this.$store.state.user.telepon;
                this.form.waiverRiderForm.email = this.$store.state.user.email;
                this.form.waiverRiderForm.dob = this.$store.state.user.tgllahir;
            }else{
                this.form.waiverRiderForm.name = "";
                this.form.waiverRiderForm.phone = "";
                this.form.waiverRiderForm.email = "";
                this.form.waiverRiderForm.dob = "";
            }
        },
        async loadListWaiver() { 
            this.$store.commit("setOverlayLoading", true);
            try {
                const res = await this.$http.post( `https://tripwe.id/apptwprdv2/waiver/getwaiver`, {
                    "id"      : "7W1CC",
                    "nobukti" : this.$store.state.waiverPackage.nobukti ?? "",
                });
                if(res.data.statuscode == "200"){
                    this.listWaiver = res.data.data;
                    for (const key in this.listWaiver) {
                        if (Object.hasOwnProperty.call(this.listWaiver, key)) {
                            const element = this.listWaiver[key];
                            if(element.sudah == '0'){
                                this.selectedWaiver = element;
                                break;
                            }
                        }
                    }
                    this.checkWaiver(this.listWaiver[0]);
                    // if(Object.keys(this.selectedWaiver).length == 0){
                    // }
                }
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit("setOverlayLoading", false);
            }
        },
        async loadWaiverRule() {   
            this.$store.commit("setOverlayLoading", true);
            try {
                var reqURL = `${this.$apiTripweWeb}/waiver/rule`;
                var param = {
                    idtoko: this.$store.state.waiverPackage.toko ?? "",
                    type: "",
                    lang: this.lang,
                };
                if (this.$store.state.waiverPackage.tipe == "OFFROAD") {
                    param.type = "2";
                }
                else if (this.$store.state.waiverPackage.tipe == "ONROAD") {
                    param.type = "3";
                    // reqURL     = "https://tripwe.id/ic/jetski-android-new/lihat_peraturan_land_adventure_v25.php";
                    // param = {
                    //  idtoko: this.$store.state.waiverPackage.toko ?? "",
                    //  type: this.$store.state.waiverPackage.tipe ?? ""
                    // }
                } else {
                    param.type = "1";
                    // reqURL     = "https://tripwe.id/ic/jetski-android-new/lihat_peraturan_seadoo_safari_v25.php";
                    // param = {
                    //  idtoko: this.$store.state.waiverPackage.toko ?? "",
                    // }
                }

                const res = await this.$http.post( reqURL, param);

                if(res.data.status == "200"){
                    this.dataRules = res.data.response;
                }
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit("setOverlayLoading", false);
            }
        },
        async checkWaiver(waiver) {   
            this.$store.commit("setOverlayLoading", true);
            this.selectedWaiver = waiver;
            try {
                var reqURL;
                var param = {};
                reqURL     = `${this.$apiTripweWebV2}/waiver/detail`;
                param = {
                    "nobukti" : this.$store.state.waiverPackage.nobukti,
                    "nowaiver": waiver.nowaiver
                }

                const res = await this.$http.post( reqURL, param);

                if(res.data.status == "200"){
                    // this.form = res.data.data;
                    for (const key in res.data.data) {
                        if (Object.hasOwnProperty.call(res.data.data, key)) {
                            const el = res.data.data[key];
                            if(el.tipeunit == "1"){
                                this.form.waiverRiderForm.name = el.nama;
                                this.form.waiverRiderForm.phone = el.telepon;
                                this.form.waiverRiderForm.email = el.email;
                                this.form.waiverRiderForm.id_card_no = el.nik;
                                this.form.waiverRiderForm.dob = el.dob;
                                this.form.waiverRiderForm.birth_place = el.pob;
                                this.form.waiverRiderForm.emergency_contact = el.emergency;
                                this.form.waiverRiderForm.emergency_contact_name = el.emergency_name;
                                this.form.waiverRiderForm.instagram = el.instagram;
                                this.form.waiverRiderForm.signature_driver = el.signature_driver;
                                this.form.waiverRiderForm.signature_toko = el.signature_toko;
                                this.onDayClick()
                                this.form.waiverRepresentativeForm.name = el.wali_nama;
                                this.form.waiverRepresentativeForm.phone = el.wali_telepon;
                                this.form.waiverRepresentativeForm.id_card_no = el.wali_nik;
                                this.form.waiverRepresentativeForm.dob = el.wali_dob;
                                this.form.created_at = el.it;
                            }else if(el.tipeunit == "2"){
                                this.form.withPassenger = true;
                                this.form.waiverPassengerForm.name = el.nama;
                                this.form.waiverPassengerForm.phone = el.telepon;
                                this.form.waiverPassengerForm.email = el.email;
                                this.form.waiverPassengerForm.id_card_no = el.nik;
                                this.form.waiverPassengerForm.dob = el.dob;
                                this.form.waiverPassengerForm.birth_place = el.pob;
                                this.form.waiverPassengerForm.emergency_contact = el.emergency;
                                this.form.waiverPassengerForm.emergency_contact_name = el.emergency_name;
                                this.form.waiverPassengerForm.instagram = el.instagram;
                            }
                        }
                    }
                    this.$toasted.global.success(`Data Waiver Unit ${waiver.nowaiver} has filled`);
                }else{
                    this.form = Object.assign({}, this.formDefault);
                }
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit("setOverlayLoading", false);
            }
        },
        async send() {
            var valid = this.validate();
            if(!valid){
                return false;
            }
            let image = null;
            const { isEmpty, data } = this.$refs.signaturePad2.saveSignature();
            if(isEmpty){
                this.$toasted.global.error("Please sign the form");
                return
            }
            image = data;
            this.$store.commit("setOverlayLoading", true);
            try {
                var reqURL;
                var param = {};
                reqURL     = `${this.$apiTripweWebV2}/waiver/store`;
                param = {
                    "nobukti"   : this.$store.state.waiverPackage.nobukti,
                    "nowaiver"  : this.selectedWaiver.nowaiver,
                    "tipeunit"  : "1",
                    "nama"      : this.form.waiverRiderForm.name,
                    "telepon"   : this.form.waiverRiderForm.phone,
                    "email"     : this.form.waiverRiderForm.email,
                    "nik"       : this.form.waiverRiderForm.id_card_no,
                    "dob"       : this.$moment(this.form.waiverRiderForm.dob).format("YYYY-MM-DD"),
                    "pob"       : this.form.waiverRiderForm.birth_place,
                    "emergency" : this.form.waiverRiderForm.emergency_contact,
                    "emergency_name" : this.form.waiverRiderForm.emergency_contact_name,
                    "instagram" : this.form.waiverRiderForm.instagram,
                    "signature_driver" : image,
                }

                if(this.form.needRepresentative){
                  param["wali_nama"] = this.form.waiverRepresentativeForm.name
                  param["wali_telepon"] = this.form.waiverRepresentativeForm.phone
                  param["wali_nik"] = this.form.waiverRepresentativeForm.id_card_no
                  param["wali_dob"] = this.$moment(this.form.waiverRepresentativeForm.dob).format("YYYY-MM-DD")
                }

                const res = await this.$http.post( reqURL, param);

                if(res.data.status == "200"){
                    if(this.form.withPassenger){
                        reqURL     = `${this.$apiTripweWebV2}/waiver/store`;
                        param = {
                            "nobukti"   : this.$store.state.waiverPackage.nobukti,
                            "nowaiver"  : this.selectedWaiver.nowaiver,
                            "tipeunit"  : "2",
                            "nama"      : this.form.waiverPassengerForm.name,
                            "telepon"   : this.form.waiverPassengerForm.phone,
                            "email"     : this.form.waiverPassengerForm.email,
                            "nik"       : this.form.waiverPassengerForm.id_card_no,
                            "dob"       : this.$moment(this.form.waiverPassengerForm.dob).format("YYYY-MM-DD"),
                            "pob"       : this.form.waiverPassengerForm.birth_place,
                            "emergency" : this.form.waiverPassengerForm.emergency_contact,
                            "emergency_name" : this.form.waiverPassengerForm.emergency_contact_name,
                            "instagram" : this.form.waiverPassengerForm.instagram,
                        }
    
                        const res2 = await this.$http.post( reqURL, param);
                        if(res2.data.status == "200"){
                            this.form = this.formDefault;
                            this.$toasted.global.success(`Data Waiver Unit ${this.selectedWaiver.nowaiver} has filled`);
                            await this.loadListWaiver();
                        }else{
                          throw res2.data.message
                        }
                    }else{
                        this.form = this.formDefault;
                        this.$toasted.global.success(`Data Waiver Unit ${this.selectedWaiver.nowaiver} has filled`);
                        await this.loadListWaiver();
                    }
                }else{
                  throw res.data.message
                }
            } catch (error) {
                this.$toasted.global.error(error);
            } finally {
                this.$store.commit("setOverlayLoading", false);
            }
        }
    }
  }
</script>
<style scoped>
.no-list-style{
    list-style-type: none;
}
</style>